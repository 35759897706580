// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$myEnergy-primary: mat.define-palette(mat.$indigo-palette);
$myEnergy-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$myEnergy-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$myEnergy-theme: mat.define-light-theme(
  (
    color: (
      primary: $myEnergy-primary,
      accent: $myEnergy-accent,
      warn: $myEnergy-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($myEnergy-theme);

/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/uplot/dist/uPlot.min.css";
@import "../scss/custom.scss";

// regular style toast
@import "ngx-toastr/toastr";

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
@import "ngx-toastr/toastr-bs4-alert";

// if you'd like to use it without importing all of bootstrap it requires
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// bootstrap 4
@import "ngx-toastr/toastr-bs4-alert";

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #c1c1c1;
}

.validate_info {
  color: red;
}

.row {
  row-gap: 30px;
}

a,
button {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.modal-backdrop {
  display: none !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

::ng-deep .toast-error {
  background-color: #f2dede !important;
  color: #db4f3f !important;
  background-color: #f8d7da;
  border: 1px solid #db4f3f !important;

  .toast-container div {
    color: #db4f3f !important;
  }
}

::ng-deep .toast-error .toast-container .ngx-toastr {
  color: #db4f3f !important;
}

::ng-deep .toast-success {
  background-color: #d7f1e5 !important;
  color: #00bf63 !important;
  border: 1px solid #00bf63 !important;

  .toast-container div {
    color: #00bf63 !important;
  }
}

.toast-container .ngx-toastr {
  margin-top: 80px;
  color: #000 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-text-field-wrapper.mdc-text-field {
  border: 1px solid #56cc9d;
  border-radius: 6px;
  font-size: 16px;
  color: #000;
  background: transparent !important;
  height: 40px;

  .mat-mdc-form-field-infix {
    min-height: 40px;
    padding-top: 8px;
  }

  .mat-mdc-floating-label {
    top: 20px;
  }
}
.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-104%) scale(0.65) !important;
  letter-spacing: 1px !important;
  color: #038846 !important;
}

.mdc-line-ripple {
  display: none !important;
}

.userListing .mat-mdc-paginator-container {
  border-top: 2px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  .mat-mdc-paginator-range-label {
    margin: 0 !important;
  }
}
.userListing__card-head mat-form-field > div {
  padding-left: 35px;
}
.requestList  {
  .mat-mdc-tab-header-pagination-chevron {
    border-color: #fff;
  }
  .mat-mdc-tab-body-content {
    overflow: inherit;
    padding: 0 0 10px;
  }
  .mat-mdc-tab-list {
    .mat-mdc-tab-labels {
      justify-content: center;
    }

    .mdc-tab {
      width: 160px;
      flex: 160px 0 0 !important;
      background-color: #fff;
      border-radius: 12px 12px 0 0;
      border: 2px solid #fff;
      font-weight: 500;
      border-bottom: none;

      &--active {
        background: rgb(0, 191, 99);
        box-shadow: 0 0px 20px 0 rgb(114 135 125 / 47%);

        .mdc-tab__text-label {
          color: #fff !important;
        }
        .mdc-tab-indicator__content {
          border-color: #00bf63;
        }
      }

      &:focus {
        .mdc-tab__text-label {
          color: #fff;
        }
      }
    }
  }
  .mdc-tab-indicator {
    display: none;
  }
}
.requestListPlans  {
  .mat-mdc-tab-header-pagination-chevron {
    height: 14px;
    width: 15px;
  }
  .mat-mdc-tab-body-content {
    overflow: inherit;
    padding: 0 0 10px;
  }
  .mat-mdc-tab-list {
    .mat-mdc-tab-labels {
      justify-content: center;
    }

    .mdc-tab {
      width: 160px;
      flex: 160px 0 0 !important;
      background-color: #fff;
      border-radius: 12px 12px 0 0;
      border: 2px solid #fff;
      font-weight: 500;
      border-bottom: none;
      .mdc-tab__text-label{
        font-weight: 600;
      }
      &--active {
        // background: rgb(0, 191, 99);
        border-bottom: 2px solid rgb(0, 191, 99);
        // box-shadow: 0 0px 20px 0 rgb(114 135 125 / 47%);

        .mdc-tab__text-label {
          color: #00bf63 !important;
        }
        .mdc-tab-indicator__content {
          border-color: #00bf63;
        }
      }

      &:focus {
        .mdc-tab__text-label {
          color: #fff;
        }
      }
    }
  }
  .mdc-tab-indicator {
    display: none;
  }
}

.formCard__Block-headField {
  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }
  .mat-date-range-input {
    top: 0 !important;
  }
  .mat-date-range-input-wrapper {
    height: 40px;
  }
  .mat-mdc-form-field-icon-suffix {
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    mat-datepicker-toggle {
      top: 0 !important;
    }
  }
}

.tooltip {
  background: #fff;
  bottom: 100%;
  color: #000;
  display: block;
  margin: 0 auto;
  font-size: 12px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px 15px;
  border-radius: 12px;
  pointer-events: none;
  position: absolute;
  transform: translateY(10px);
  transition: all 0.25s ease-out;
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  width: 250px;
}
/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.tooltipBlock {
  &--center {
    position: relative;
    display: flex;
    justify-content: center;
  }
}
.tooltipBlock .tooltip:before {
  bottom: -18px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}
/* CSS Triangles - see Trevor's post */
.tooltipBlock .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #fff 10px;
  bottom: -8px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}
.tooltipBlock:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
/* IE can just show/hide with no transition */
.tooltipBlock .tooltip {
  display: none;
}
.tooltipBlock:hover .tooltip {
  display: block;
}

.login_card_wrapper form .form-group.form-check {
  margin: 20px 0 40px !important;
  padding: 0 !important;
  mat-radio-group {
    display: grid !important;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 8px;
    .mdc-label {
      margin: 0 !important;
    }
  }
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #00bf63;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #00bf63 !important;
}
.langBlock {
  width: 62px;
  .mat-mdc-text-field-wrapper {
    padding: 0;
  }
  .mat-mdc-form-field-infix {
    padding: 8px !important;
    height: 40px;
  }
  mat-select-trigger {
    display: flex;
    align-items: center;
  }
}
.mat-mdc-select-panel {
  min-width: 175px;
  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px !important;
    color: #000;
    font-weight: 500 !important;
    img {
      width: 30px;
      object-fit: contain;
      height: 22px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .login_card_wrapper form .form-group.form-check mat-radio-group {
    grid-template-columns: 1fr;
  }
}
